import React, { useState, useEffect, useCallback, useRef } from 'react';

import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { Textarea } from '../components/catalyst/textarea'

import { Fieldset, Label, Field } from '../components/catalyst/fieldset';
import { Button } from '../components/catalyst/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';


// const macro = {
//   title: 'Normal EKG',
//   command: 'Insert Normal EKG',
//   content: 'Normal EKG content',
// };

export const MacrosPage = () => {
  // State variables
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const [macroTitle, setMacroTitle] = React.useState('');
  const [macroCommand, setMacroCommand] = React.useState('');
  const [macroContent, setMacroContent] = React.useState('');
  const [macroSection, setMacroSection] = React.useState('reevaluation');
  const [isLoading, setIsLoading] = React.useState(false);
  const [macroList, setMacroList] = React.useState([]);
  const [loadingMessage, setLoadingMessage] = useState('Loading...');
  const [selectedMacroIndex, setSelectedMacroIndex] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [lastUpsertedMacroTitle, setLastUpsertedMacroTitle] = React.useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMacroTitle, setSelectedMacroTitle] = useState('');




  const reverseSectionMapping = {
    "hpi": "HPI",
    "ros": "Review of Systems",
    "physical_exam": "Physical Exam",
    "reevaluation": "Evaluations"
  }

  const handleTokenExpiration = useCallback((error) => {
    if (error instanceof InteractionRequiredAuthError) {
      instance.logoutRedirect();
    } else {
      console.error('Error:', error);
    }
  }, [instance]);

  const upsertMacro = async () => {
    setIsLoading(true);
    try {
      const request = {
        scopes: protectedResources.apiUpsertMacro.scopes,
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          "title": macroTitle,
          "command": macroCommand, "content": macroContent, "section": macroSection
        })
      };

      const fetchResponse = await fetch(protectedResources.apiUpsertMacro.endpoint, requestOptions);
      setLastUpsertedMacroTitle(macroTitle);

      await getMacros(macroTitle);

    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error updating document:', error);
      // Handle any errors here
    }
    setIsLoading(false);
  };



  const deleteMacro = async () => {
    setIsLoading(true);
    try {
      const request = {
        scopes: protectedResources.apiDeleteMacro.scopes,
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ "title": macroTitle })
      };

      const fetchResponse = await fetch(protectedResources.apiDeleteMacro.endpoint, requestOptions);

      setMacroTitle('');
      setMacroCommand('');
      setMacroContent('');
      setMacroSection('reevaluation');
      setSelectedMacroIndex(null);

      await getMacros();

    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error deleting document:', error);
      // Handle any errors here
    }
    setIsLoading(false);
  };

  const handleMacroClick = (macro) => {
    setMacroTitle(macro.title);
    setMacroCommand(macro.command);
    setMacroContent(macro.content);
    setMacroSection(macro.section || 'reevaluation');
    setSelectedMacroTitle(macro.title);
    setIsEditable(false);
  };




  const getMacros = useCallback(async (lastUpsertedMacroTitle = '') => {
    setIsLoading(true);
    try {
      const request = {
        scopes: protectedResources.apiGetMacros.scopes,
        account: accounts[0]
      };

      const response = await instance.acquireTokenSilent(request);
      const token = response.accessToken;

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };

      const fetchResponse = await fetch(protectedResources.apiGetMacros.endpoint, requestOptions);
      const data = await fetchResponse.json();

      const macros = Object.keys(data.macros).map(key => ({
        title: key,
        command: data.macros[key].command,
        content: data.macros[key].content,
        section: data.macros[key].section
      }));
      setMacroList(macros);


      const foundIndex = macros.findIndex(macro => macro.title === lastUpsertedMacroTitle);
      setSelectedMacroIndex(foundIndex);
      setSelectedMacroTitle(lastUpsertedMacroTitle);
      setLastUpsertedMacroTitle(null); // Reset after use

    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error updating document:', error);
      // Handle any errors here
    }
    setIsLoading(false);
  }, [instance, accounts]);

  const isDuplicateTitle = (title) => {
    return macroList.some(macro => macro.title.toLowerCase() === title.toLowerCase());
  };

  const handleSaveClick = () => {
    if (!areFieldsFilled()) {
      alert("Please fill in all the required fields.");
      return;
    }

    if (selectedMacroIndex === null && isDuplicateTitle(macroTitle)) {
      alert("A macro with this title already exists. Please use a different title.");
      return;
    }

    upsertMacro();
    setIsEditable(false);
  };

  const areFieldsFilled = () => {
    return macroTitle.trim() !== '' && macroCommand.trim() !== '' && macroContent.trim() !== '';
  };




  const handleDeleteClick = () => {
    deleteMacro()
  };

  const goBack = () => {
    navigate('/er-dashboard');
  };

  const handleNewMacro = () => {
    setMacroTitle('');
    setMacroCommand('');
    setMacroContent('');
    setMacroSection('reevaluation');
    setSelectedMacroIndex(null);
    setSelectedMacroTitle('');
    setIsEditable(true);
  };


  useEffect(() => {
    const loading = inProgress === InteractionStatus.Startup ||
      inProgress === InteractionStatus.HandleRedirect ||
      inProgress === InteractionStatus.Login ||
      inProgress === InteractionStatus.Logout;

    setIsLoading(loading);
    const timeoutId = setTimeout(() => {
      getMacros();
    }, 250); // Delay of 250 milliseconds

    // Cleanup function to clear the timeout if the component unmounts
    // or dependencies change before the timeout is completed
    return () => clearTimeout(timeoutId);
  }, [getMacros]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      {isLoading ? (
        <div className="loading-overlay">
          <div className="spinner"></div>
          <div className="loading-text">{loadingMessage}</div>
        </div>
      ) : (
        <div className="flex flex-col w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
          <div className="header-container mb-6 mt-6 w-full">
            <div className="header-row flex justify-between items-center px-6">
              <Button onClick={goBack} className="bg-indigo-900 hover:bg-indigo-900 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center">
                <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                Back to Dashboard
              </Button>
            </div>
          </div>
          <div className="flex w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
            {/* Left Column */}
            <div className="w-1/4 border-r border-gray-200 p-6">
              <Button
                className="bg-blue-500 hover:bg-blue-700 text-white py-1.5 px-3 rounded focus:outline-none focus:shadow-outline mb-4"
                onClick={handleNewMacro}>
                + New Empty Macro
              </Button>
              <input
                type="text"
                placeholder="Search macros"
                className="mt-2 mb-4 p-2 border border-gray-300 rounded w-full"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
                <ul className="space-y-0">
                  {macroList
                    .filter(macro => macro.title.toLowerCase().includes(searchQuery.toLowerCase()))
                    .map((m, index) => (
                      <li key={index}
                        className={`cursor-pointer text-zinc-700 hover:bg-gray-100 p-2 ${m.title === selectedMacroTitle ? 'bg-gray-200' : ''} 
      ${index === 0 ? 'border-t border-b border-gray-200' : 'border-b border-gray-200'}`}
                        onClick={() => handleMacroClick(m)}>
                        {m.title}
                      </li>
                    ))}
                </ul>


              </div>
            </div>

            {/* Right Column */}
            <div className="w-3/4 p-6">
              {/* Field for Title */}
              <div className="flex flex-col space-y-6">
                <label className="text-base/4 text-zinc-700 block font-bold">Title</label>
                <textarea
                  name="macroTitle"
                  className={`mt-2 mb-3 block w-full text-slate-700 rounded-md border py-1 px-2 ${selectedMacroIndex !== null && selectedMacroIndex !== -1 || !isEditable
                    ? 'bg-gray-100 cursor-not-allowed text-gray-500'
                    : 'bg-white border-gray-300'
                    }`}
                  rows="1"
                  placeholder="Enter title here"
                  value={macroTitle}
                  onChange={(e) => setMacroTitle(e.target.value)}
                  disabled={selectedMacroIndex !== null && selectedMacroIndex !== -1 || !isEditable}
                  required
                />
              </div>

              {/* Field for Command */}
              <div className="flex flex-col space-y-6">
                <label className="text-base/4 text-zinc-700 block font-bold">When I say this during my patient encounter...</label>
                <textarea
                  name="macroCommand"
                  className="mt-2 mb-3 block w-full text-slate-700 rounded-md border border-gray-300 py-1 px-2 bg-white"
                  rows="1"
                  placeholder="Enter command here"
                  value={macroCommand}
                  onChange={(e) => setMacroCommand(e.target.value)}
                  required
                />
              </div>

              {/* Field for Content */}
              <div className="flex flex-col space-y-6">
                <label className="text-base/4 text-zinc-700 block font-bold">Insert this content...</label>
                <textarea
                  name="macroContent"
                  className="mt-2 mb-3 block w-full text-slate-700 rounded-md border border-gray-300 py-1 px-2 bg-white"
                  rows="9"
                  placeholder="Enter content here"
                  value={macroContent}
                  onChange={(e) => setMacroContent(e.target.value)}
                  required
                />
              </div>

              {/* Field for Section */}
              <div className="flex flex-col space-y-6">
                <label className="text-base/4 text-zinc-700 block font-bold">Into this section of my note...</label>
                <select
                  name="macroSection"
                  className="mt-2 mb-3 block w-full text-slate-700 rounded-md border border-gray-300 py-1 px-2 bg-white"
                  value={macroSection || 'reevaluation'}
                  onChange={(e) => setMacroSection(e.target.value)}
                >
                  <option value="hpi">HPI</option>
                  <option value="ros">Review of Systems</option>
                  <option value="physical_exam">Physical Exam (this macro will replace your Cleo generated physical)</option>
                  <option value="reevaluation">Evaluations</option>
                </select>
              </div>

              <div className="flex justify-between mt-4">
                <Button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleDeleteClick}>
                  Delete
                </Button>
                <div className="flex space-x-2">
                  <Button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleSaveClick} disabled={!areFieldsFilled()}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

};

export default MacrosPage;