'use client'

import { useState, useCallback, React, useEffect, useRef } from 'react'
import { Link, Route, Routes, useLocation, Navigate, useNavigate } from 'react-router-dom'
import { Dialog, DialogPanel, Field, Label, Switch } from '@headlessui/react'
import { Bars3Icon, CheckCircleIcon, ArrowLeftIcon, TrashIcon, CheckIcon } from '@heroicons/react/20/solid'
import { useAuth } from '../contexts/AuthProvider';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import {
    BellIcon,
    CreditCardIcon,
    CubeIcon,
    FingerPrintIcon,
    UserCircleIcon,
    UsersIcon,
    XMarkIcon,
    PencilSquareIcon,
    CommandLineIcon
} from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../components/catalyst/button';

const secondaryNavigation = [
    { name: 'Profile', href: '/settings/profile', icon: UserCircleIcon, component: ProfileSettings },
    { name: 'Custom Instructions', href: '/settings/custom-instructions', icon: PencilSquareIcon, component: CustomInstructions },
    { name: 'Macros', href: '/settings/macros', icon: CubeIcon, component: MacroSettings },
    // { name: 'Security', href: '/settings/security', icon: FingerPrintIcon, component: SecuritySettings },
    // { name: 'Notifications', href: '/settings/notifications', icon: BellIcon, component: NotificationSettings },
    // { name: 'Billing', href: '/settings/billing', icon: CreditCardIcon, component: BillingSettings },
    // { name: 'Team members', href: '/settings/team', icon: UsersIcon, component: TeamMemberSettings },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function SuccessMessage({ message, onDismiss }) {
    return (
        <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
                </div>
                <div className="ml-3">
                    {/* <p className="text-sm font-medium text-green-800">{message}</p> */}
                    <p className="text-sm font-medium text-green-800">Succcessfully saved!</p>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            onClick={onDismiss}
                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default function Settings() {
    const [successMessage, setSuccessMessage] = useState(null)
    const location = useLocation()
    const navigate = useNavigate();
    const { actorInfo, isLoading } = useAuth();

    const handleSuccessfulSave = (message) => {
        setSuccessMessage(message)
        setTimeout(() => setSuccessMessage(null), 5000)
    }

    const handleBackToDashboard = () => {
        if (isLoading) {
            return; // Don't navigate if still loading
        }

        if (actorInfo?.actor_specialty === 'EMERGENCY_PHYSICIAN') {
            navigate('/er-dashboard');
        } else if (actorInfo?.actor_specialty === 'HOSPITALIST') {
            navigate('/hospitalist-note-dashboard');
        } else {
            // Default fallback, or handle unknown roles
            navigate('/');
        }
    };

    return (
        <div className="flex min-h-screen">
            {successMessage && (
                <div className="fixed inset-x-0 top-4 flex justify-center z-50">
                    <div className="max-w-sm w-full">
                        <SuccessMessage
                            message={successMessage}
                            onDismiss={() => setSuccessMessage(null)}
                        />
                    </div>
                </div>
            )}

            <aside className="w-64 flex-shrink-0 bg-white border-r border-gray-200">
                <nav className="h-full overflow-y-auto py-20 px-4">
                    <ul role="list" className="space-y-1">
                        <li>
                            <button
                                onClick={handleBackToDashboard}
                                className="group flex w-full items-center gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-800"
                            >
                                <ArrowLeftIcon
                                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-800"
                                    aria-hidden="true"
                                />
                                Back to Dashboard
                            </button>
                        </li>
                        {secondaryNavigation.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.href}
                                    className={classNames(
                                        location.pathname === item.href
                                            ? 'bg-gray-50 text-indigo-800'
                                            : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                        'group flex w-full gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6',
                                    )}
                                >
                                    <item.icon
                                        aria-hidden="true"
                                        className={classNames(
                                            location.pathname === item.href ? 'text-indigo-800' : 'text-gray-400 group-hover:text-indigo-800',
                                            'h-6 w-6 shrink-0',
                                        )}
                                    />
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </aside>

            <main className="flex-grow flex justify-center">
                <div className="w-full max-w-3xl px-4 py-16 sm:px-6 lg:px-8">
                    <Routes>
                        {secondaryNavigation.map((item) => (
                            <Route
                                key={item.href}
                                path={item.href.replace('/settings/', '')}
                                element={<item.component onSuccessfulSave={handleSuccessfulSave} />}
                            />
                        ))}
                        <Route path="*" element={<Navigate to="/settings/profile" replace />} />
                    </Routes>
                </div>
            </main>
        </div>
    )
}

const titleToFieldMap = {
    "History of Present Illness": "custom_instructions_hpi",
    "Review of Systems": "custom_instructions_ros",
    "Physical Exam": "custom_instructions_physical_exam",
    "Evaluation and Plan": "custom_instructions_reevaluation"
};

const suggestedInstructions = {
    "History of Present Illness": ["Format my HPI with medical abbreviations.", "Break out my HPI into subsections (HPI, PMH/PSH, Allergies, Medications, Social History.)"],
    "Review of Systems": ["Include quotes from the patient about their symptoms.", "Always integrate findings into my normal ROS macro."],
    "Physical Exam": ["Always integrate findings into my normal physical exam macro.", "If patient has back pain, include my back pain macro."],
    "Evaluation and Plan": ["Always include the following PA attestation...", "If I mention getting an EKG, automatically include a normal EKG macro."]
};

function CustomInstructions({ onSuccessfulSave }) {
    const { actorInfo, isLoading } = useAuth();


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Custom Instructions</h1>
            <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-6">
                <p className="text-blue-700">
                    Customize your Cleo notes by providing section specific instructions.
                </p>
            </div>
            <CustomInstructionSection title="History of Present Illness" onSuccessfulSave={onSuccessfulSave} />
            <CustomInstructionSection title="Review of Systems" onSuccessfulSave={onSuccessfulSave} />
            <CustomInstructionSection title="Physical Exam" onSuccessfulSave={onSuccessfulSave} />
            <CustomInstructionSection title="Evaluation and Plan" onSuccessfulSave={onSuccessfulSave} />
        </>
    );
}

function CustomInstructionSection({ title, onSuccessfulSave }) {
    const [instructions, setInstructions] = useState('');
    const { instance, accounts } = useMsal();
    const { actorInfo, isLoading, updateActorInfo } = useAuth();
    const [charCount, setCharCount] = useState(0);
    const maxChars = 300;
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (actorInfo && !isLoading) {
            console.log("actorInfo:", actorInfo);
            const fieldName = titleToFieldMap[title];
            const customInstruction = actorInfo[fieldName] || '';
            setInstructions(customInstruction.slice(0, maxChars));
            setCharCount(customInstruction.length);
        }
    }, [actorInfo, isLoading, title]);

    const handleInputChange = (e) => {
        const input = e.target.value.slice(0, maxChars);
        setInstructions(input);
        setCharCount(input.length);
    };

    const insertSuggestedInstruction = (suggestion) => {
        const newInstructions = suggestion.slice(0, maxChars);
        setInstructions(newInstructions);
        setCharCount(newInstructions.length);
    };

    const updateActor = async (fieldToUpdate, newValue, instance, account) => {
        try {
            const request = {
                scopes: protectedResources.apiUpdateActorSettings.scopes,
                account: account
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    field: fieldToUpdate,
                    value: newValue
                })
            };

            await fetch(protectedResources.apiUpdateActorSettings.endpoint, requestOptions);
            updateActorInfo(fieldToUpdate, newValue);
        } catch (error) {
            console.error('Error updating actor:', error);
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        if (accounts.length === 0) {
            console.error('No accounts found');
            return;
        }
        const fieldName = titleToFieldMap[title];
        try {
            await updateActor(fieldName, instructions, instance, accounts[0]);
            onSuccessfulSave(title);
        } catch (error) {
            console.error('Error updating actor:', error);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="mt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
            <div className="mt-2 mb-2">
                {suggestedInstructions[title].map((suggestion, index) => (
                    <button
                        key={index}
                        onClick={() => insertSuggestedInstruction(suggestion)}
                        className="mr-2 mb-2 px-2 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded"
                    >
                        {suggestion}
                    </button>
                ))}
            </div>
            <div className="mt-2">
                <form onSubmit={handleSave} className="relative">
                    <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                        <textarea
                            rows={5}
                            value={instructions}
                            onChange={handleInputChange}
                            className="block w-full resize-none border-0 bg-transparent py-1.5 pr-24 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={`Enter custom instructions for ${title}...`}
                        />
                        <div className="absolute bottom-0 right-0 p-2 flex items-center">
                            <span className="text-sm text-gray-500 mr-2">
                                {charCount}/{maxChars}
                            </span>
                            <button
                                type="submit"
                                className="inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

function ProfileSettings() {
    const { actorInfo, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const formatSpecialty = (specialty) => {
        if (!specialty) return 'N/A';
        return specialty
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Profile</h1>
            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <p className="text-sm leading-6 text-gray-500 mb-6">
                            Contact admin@cleo-ai.co to update your profile.
                        </p>

                        <dl className="space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex sm:justify-between">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                                <dd className="mt-1 sm:mt-0 text-gray-900 sm:text-right">
                                    {actorInfo?.full_name || 'N/A'}
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex sm:justify-between">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                                <dd className="mt-1 sm:mt-0 text-gray-900 sm:text-left">
                                    {actorInfo?.email || 'N/A'}
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex sm:justify-between">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Title</dt>
                                <dd className="mt-1 sm:mt-0 text-gray-900 sm:text-left">
                                    {formatSpecialty(actorInfo?.actor_specialty)}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </>
    );
}

function MacroSettings({ onSuccessfulSave }) {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const [macroTitle, setMacroTitle] = useState('');
    const [macroCommand, setMacroCommand] = useState('');
    const [macroContent, setMacroContent] = useState('');
    const [macroSection, setMacroSection] = useState('reevaluation');
    const [isLoading, setIsLoading] = useState(false);
    const [macroList, setMacroList] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [selectedMacroIndex, setSelectedMacroIndex] = useState(null);
    const [isEditable, setIsEditable] = useState(true);
    const [lastUpsertedMacroTitle, setLastUpsertedMacroTitle] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedMacroTitle, setSelectedMacroTitle] = useState('');

    const reverseSectionMapping = {
        "hpi": "HPI",
        "ros": "Review of Systems",
        "physical_exam": "Physical Exam",
        "reevaluation": "Evaluations"
    }

    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const upsertMacro = async () => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiUpsertMacro.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "title": macroTitle,
                    "command": macroCommand, "content": macroContent, "section": macroSection
                })
            };

            const fetchResponse = await fetch(protectedResources.apiUpsertMacro.endpoint, requestOptions);
            setLastUpsertedMacroTitle(macroTitle);

            await getMacros(macroTitle);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };



    const deleteMacro = async () => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiDeleteMacro.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "title": macroTitle })
            };

            const fetchResponse = await fetch(protectedResources.apiDeleteMacro.endpoint, requestOptions);

            setMacroTitle('');
            setMacroCommand('');
            setMacroContent('');
            setMacroSection('reevaluation');
            setSelectedMacroIndex(null);

            await getMacros();

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error deleting document:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    };

    const handleMacroClick = (macro) => {
        setMacroTitle(macro.title);
        setMacroCommand(macro.command);
        setMacroContent(macro.content);
        setMacroSection(macro.section || 'reevaluation');
        setSelectedMacroTitle(macro.title);
        setIsEditable(false);
    };

    const getMacros = useCallback(async (lastUpsertedMacroTitle = '') => {
        setIsLoading(true);
        try {
            const request = {
                scopes: protectedResources.apiGetMacros.scopes,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);
            const token = response.accessToken;

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            };

            const fetchResponse = await fetch(protectedResources.apiGetMacros.endpoint, requestOptions);
            const data = await fetchResponse.json();

            const macros = Object.keys(data.macros).map(key => ({
                title: key,
                command: data.macros[key].command,
                content: data.macros[key].content,
                section: data.macros[key].section
            }));
            setMacroList(macros);

            const foundIndex = macros.findIndex(macro => macro.title === lastUpsertedMacroTitle);
            setSelectedMacroIndex(foundIndex);
            setSelectedMacroTitle(lastUpsertedMacroTitle);
            setLastUpsertedMacroTitle(null); // Reset after use

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
        setIsLoading(false);
    }, [instance, accounts]);

    const isDuplicateTitle = (title) => {
        return macroList.some(macro => macro.title.toLowerCase() === title.toLowerCase());
    };

    const handleSaveClick = () => {
        if (!areFieldsFilled()) {
            alert("Please fill in all the required fields.");
            return;
        }

        if (selectedMacroIndex === null && isDuplicateTitle(macroTitle)) {
            alert("A macro with this title already exists. Please use a different title.");
            return;
        }

        upsertMacro();
        setIsEditable(false);
        onSuccessfulSave(`Macro "${macroTitle}" saved successfully`);
    };

    const areFieldsFilled = () => {
        return macroTitle.trim() !== '' && macroCommand.trim() !== '' && macroContent.trim() !== '';
    };

    const handleDeleteClick = () => {
        deleteMacro()
    };

    const goBack = () => {
        navigate('/er-dashboard');
    };

    const handleNewMacro = () => {
        setMacroTitle('');
        setMacroCommand('');
        setMacroContent('');
        setMacroSection('reevaluation');
        setSelectedMacroIndex(null);
        setSelectedMacroTitle('');
        setIsEditable(true);
    };

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        const timeoutId = setTimeout(() => {
            getMacros();
        }, 250); // Delay of 250 milliseconds

        // Cleanup function to clear the timeout if the component unmounts
        // or dependencies change before the timeout is completed
        return () => clearTimeout(timeoutId);
    }, [getMacros]);

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Macro Settings</h1>
            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div className="flex">
                        {/* Left Column */}
                        <div className="w-1/3 pr-4">
                            <button
                                className="inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4"
                                onClick={handleNewMacro}>
                                + New Empty Macro
                            </button>
                            <input
                                type="text"
                                placeholder="Search macros"
                                className="mt-2 mb-4 p-2 border border-gray-300 rounded w-full"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
                                {isLoading ? (
                                    <div className="flex justify-center items-center h-full">
                                        <div className="spinner"></div>
                                    </div>
                                ) : macroList.length > 0 ? (
                                    <ul className="space-y-0">
                                        {macroList
                                            .filter(macro => macro.title.toLowerCase().includes(searchQuery.toLowerCase()))
                                            .map((m, index) => (
                                                <li key={index}
                                                    className={`cursor-pointer text-zinc-700 hover:bg-gray-100 p-2 ${m.title === selectedMacroTitle ? 'bg-gray-200' : ''} 
                        ${index === 0 ? 'border-t border-b border-gray-200' : 'border-b border-gray-200'}`}
                                                    onClick={() => handleMacroClick(m)}>
                                                    {m.title}
                                                </li>
                                            ))}
                                    </ul>
                                ) : (
                                    <p className="text-center text-gray-500 py-4">No macros found</p>
                                )}
                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="w-2/3 pl-4">
                            <div className="space-y-6">
                                {/* Title */}
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                                    <textarea
                                        name="macroTitle"
                                        className={`mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${selectedMacroIndex !== null && selectedMacroIndex !== -1 || !isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : ''}`}
                                        rows="1"
                                        placeholder="Enter title here"
                                        value={macroTitle}
                                        onChange={(e) => setMacroTitle(e.target.value)}
                                        disabled={selectedMacroIndex !== null && selectedMacroIndex !== -1 || !isEditable}
                                        required
                                    />
                                </div>

                                {/* Command */}
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">When I say this during my patient encounter...</label>
                                    <textarea
                                        name="macroCommand"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        rows="1"
                                        placeholder="Enter command here"
                                        value={macroCommand}
                                        onChange={(e) => setMacroCommand(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* Content */}
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Insert this content...</label>
                                    <textarea
                                        name="macroContent"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        rows="6"
                                        placeholder="Enter content here"
                                        value={macroContent}
                                        onChange={(e) => setMacroContent(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* Section */}
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Into this section of my note...</label>
                                    <select
                                        name="macroSection"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={macroSection || 'reevaluation'}
                                        onChange={(e) => setMacroSection(e.target.value)}
                                    >
                                        <option value="hpi">HPI</option>
                                        <option value="ros">Review of Systems</option>
                                        <option value="physical_exam">Physical Exam (this macro will replace your Cleo generated physical)</option>
                                        <option value="reevaluation">Evaluations</option>
                                    </select>
                                </div>

                                <div className="flex justify-between mt-4">
                                    <button
                                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-50 ring-1 ring-inset ring-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
                                        onClick={handleDeleteClick}>
                                        <TrashIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                        Delete
                                    </button>
                                    <button
                                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                        onClick={handleSaveClick}
                                        disabled={!areFieldsFilled()}>
                                        <CheckCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function SecuritySettings() {
    return <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Security Settings</h1>
}

function NotificationSettings() {
    return <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Notification Settings</h1>
}

function BillingSettings() {
    return <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Billing Settings</h1>
}

function TeamMemberSettings() {
    return <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Team Member Settings</h1>
}