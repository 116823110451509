import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { protectedResources } from '../authConfig';
import { useNavigate } from 'react-router-dom';
import '../styles/NoteDashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisH, faCalendarAlt, faComments, faClipboardList, faSpinner, faBell, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom';

import { Fieldset, Legend } from '../components/catalyst/fieldset';
import { Text } from '../components/catalyst/text';




function HospitalistNoteDashboard() {
    const { instance, accounts, inProgress } = useMsal();
    const [documents, setDocuments] = useState([]);
    const location = useLocation();
    const providedDate = location.state?.providedDate;
    const actorInfo = location.state?.actorInfo;
    const [selectedDate, setSelectedDate] = useState(providedDate ? new Date(providedDate) : new Date());
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const [selectedDateRange, setSelectedDateRange] = useState(() => {
        // Start date at the beginning of today in UTC
        console.log("Provided date:", providedDate);
        console.log("Selected date:", selectedDate);
        const startUtc = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));

        // End date at the end of tomorrow in UTC
        const tomorrow = new Date(startUtc);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const endUtc = new Date(Date.UTC(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 23, 59, 59, 999));

        // Calculate the user's local time zone offset from UTC in hours
        const localTimeZoneOffsetHours = new Date().getTimezoneOffset() / 60;

        // Adjust the UTC time range by the local time zone offset
        startUtc.setHours(startUtc.getHours() + localTimeZoneOffsetHours);
        endUtc.setHours(endUtc.getHours() + localTimeZoneOffsetHours);

        return { start: startUtc, end: endUtc };
    });



    const handleDateChange = (date) => {
        // Set the UTC time range as a full day from midnight to midnight
        const startUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        const endUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));

        // Calculate the user's local time zone offset from UTC in hours
        const localTimeZoneOffsetHours = new Date().getTimezoneOffset() / 60;

        // Adjust the UTC time range by the local time zone offset
        startUtc.setHours(startUtc.getHours() + localTimeZoneOffsetHours);
        endUtc.setHours(endUtc.getHours() + localTimeZoneOffsetHours);

        console.log("Adjusted UTC Start date being sent to backend:", startUtc.toISOString());
        console.log("Adjusted UTC End date being sent to backend:", endUtc.toISOString());
        setIsDatePickerOpen(false);

        setSelectedDateRange({ start: startUtc, end: endUtc });
        setSelectedDate(date); // Keep the original date for DatePicker

    };

    const handleTokenExpiration = useCallback((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            instance.logoutRedirect();
        } else {
            console.error('Error:', error);
        }
    }, [instance]);

    const fetchAllReports = useCallback(async () => {
        setIsLoading(true);
        if (accounts.length > 0) {


            try {
                const request = {
                    scopes: protectedResources.apiGetDocumentsForDay.scopes,
                    account: accounts[0]
                };

                const response = await instance.acquireTokenSilent(request);
                const token = response.accessToken;

                const formattedStartDate = selectedDateRange.start.toISOString();
                const formattedEndDate = selectedDateRange.end.toISOString();
                console.log("UTC Start date being sent to backend:", formattedStartDate);
                console.log("UTC End date being sent to backend:", formattedEndDate);


                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        start_date: formattedStartDate,
                        end_date: formattedEndDate,
                    })
                };

                const fetchResponse = await fetch(protectedResources.apiGetDocumentsForDay.endpoint, requestOptions);
                const data = await fetchResponse.json();


                if (data.documents && data.documents.length > 0) {
                    data.documents.forEach(doc => console.log(`Raw created_at from server: ${doc.created_at}`));

                    // Sort documents by 'created_at' in descending order
                    const filteredDocuments = data.documents.filter(doc => doc.document_status !== "FINALIZED" && doc.document_status !== "PRE_ENCOUNTER");
                    const sortedDocuments = filteredDocuments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    // const sortedDocuments = data.documents.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setDocuments(sortedDocuments);
                } else {
                    setDocuments([]);
                }

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        } setIsLoading(false);
    }, [instance, accounts, selectedDateRange, handleTokenExpiration]);

    useEffect(() => {
        const loading = inProgress === InteractionStatus.Startup ||
            inProgress === InteractionStatus.HandleRedirect ||
            inProgress === InteractionStatus.Login ||
            inProgress === InteractionStatus.Logout;

        setIsLoading(loading);
        fetchAllReports();
    }, [fetchAllReports, selectedDate, inProgress, actorInfo]);



    const handleDocumentClick = (doc) => {
        navigate('/hospitalist-chart', { state: { document: doc, providedDate: selectedDate } });
    };


    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };


    return (
        <div className="dashboard-container">
            <div className="w-full py-4 mb-1 rounded-md">
                <Fieldset className="flex flex-row justify-between items-center">
                    <Fieldset className="flex items-center space-x-4">
                        <FontAwesomeIcon icon={faClipboardList} className="text-indigo-600 text-3xl" />
                        <Fieldset>
                            <h1 className="text-2xl font-bold text-indigo-800">Note Dashboard</h1>
                            <p className="text-md text-indigo-600">Track and manage your documentation efficiently.</p>
                        </Fieldset>
                    </Fieldset>
                    {actorInfo &&
                        actorInfo.division === 'D2C' &&
                        (actorInfo.subscription_type === 'FREE_TRIAL' || actorInfo.subscription_type === 'NO_SUBSCRIPTION') ? (
                        <div>
                            <button
                                type="button"
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => navigate("/subscribe", { state: { actorInfo: actorInfo } })}
                            >
                                Upgrade your Cleo Health Subscription
                                <FontAwesomeIcon icon={faBell} className="ml-2 mt-1" />
                            </button>
                        </div>

                    ) : null}
                    <Fieldset
                        className="flex flex-col justify-center items-center py-3 rounded-lg bg-white shadow w-1/10 cursor-pointer"
                        onClick={() => setIsDatePickerOpen(true)}
                    >
                        <FontAwesomeIcon icon={faCalendarAlt} className="text-indigo-500 text-md mb-2" />
                        Select Date
                        <DatePicker
                            id="datePicker"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            open={isDatePickerOpen}
                            onSelect={() => setIsDatePickerOpen(false)} // Close on date select
                            onClickOutside={() => setIsDatePickerOpen(false)} // Close on outside click
                            className="w-full text-sm text-center font-semibold text-gray-700 outline-none cursor-pointer border-none"
                        />
                    </Fieldset>



                </Fieldset>

            </div>

            {isLoading ? (
                <div className="loading-overlay">
                    <div className="spinner"></div>
                    <div className="loading-text">Loading notes...</div>
                </div>
            ) : (
                documents.length > 0 ? (

                    <Fieldset className="w-full bg-white rounded-lg">
                        <Fieldset className="bg-gray-100 rounded-lg shadow-lg mb-4 hover:bg-gray-100 cursor-pointer px-4 py-3 transition duration-300 ease-in-out">
                            <Fieldset className="flex flex-row items-center justify-between">
                                <Text className="w-3/4 font-bold">Patient Info</Text>
                                <Text className="w-1/8 font-bold text-center">Created At</Text>
                                <Text className="w-1/8 font-bold text-center">Status</Text>
                            </Fieldset>
                        </Fieldset>
                        {documents.map((doc, index) => (
                            <Fieldset key={index}
                                className="bg-white rounded-lg shadow-lg mb-2 hover:bg-gray-200 cursor-pointer p-4 transition duration-300 ease-in-out"
                                onClick={() => handleDocumentClick(doc)}>
                                <Fieldset className="flex flex-row items-center justify-between">
                                    <Fieldset className='w-3/4'>
                                        <Text className="font-bold text-black">{doc.patient_name}</Text>
                                        <Fieldset className="flex flex-row items-center">
                                            <FontAwesomeIcon icon={faComments} className="mr-1 mt-1 text-indigo-800 text-sm" />
                                            <Text className="text-black">{doc.procedure_description}</Text>
                                        </Fieldset>
                                    </Fieldset>
                                    <Text className="w-1/8 text-center text-black">{formatTime(doc.created_at)}</Text>
                                    <Fieldset className="w-1/8 text-center pr-1">
                                        {doc.document_status === "ENCOUNTER_COMPLETE" ? (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
                                            </Fieldset>
                                        ) : doc.document_status === "MOBILE_FINALIZED" ? (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-green-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faCheck} className="text-green-600" />
                                            </Fieldset>
                                        ) : doc.document_status === "IN_PROGRESS" ? (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-yellow-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faSpinner} className="fa-spin text-yellow-600" />
                                            </Fieldset>
                                        ) : (
                                            <Fieldset className="flex justify-center items-center w-8 h-8 bg-gray-200 rounded-lg text-xl">
                                                <FontAwesomeIcon icon={faEllipsisH} className="text-gray-500" />
                                            </Fieldset>
                                        )}
                                    </Fieldset>
                                </Fieldset>
                            </Fieldset>
                        ))}
                    </Fieldset>
                ) : (
                    <Fieldset className="w-full flex flex-col items-center justify-center h-full">
                        <Legend>No Documents Available</Legend>
                        <Text className="mt-4 text-center">
                            There are no documents available for the selected date. Please select a different date or check back later.
                        </Text>
                    </Fieldset>
                )
            )}

            <div className="feedback-link">
                <a
                    data-canny-link
                    href="https://cleo-health.canny.io"
                    target="_blank"
                    className="inline-flex justify-center items-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                    Request a Feature
                </a>
            </div>


        </div>
    );





}

export default HospitalistNoteDashboard;
